import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomFormService} from '../../core/custom-form.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {CustomFormSubmission, Field} from '../../models/CustomForm';
import {PagingAndFilteringInfo} from '../../models/GeneralTransfer';

@Component({
  selector: 'app-submittions',
  templateUrl: './submittions.component.html',
  providers: [MessageService]
})
export class SubmittionsComponent implements OnInit {

  submissions: CustomFormSubmission[] = [];
  formId = 0;
  totalRecords =  0;
  loading: boolean;
  pageSize = 10;
  cols: any[];
  selectedSubmissionFields: Field[] = [];
  showViewDlg = false;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private apiService: CustomFormService,
      private messageService: MessageService, private msgService: SystemMessagingService)
  { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.formId = +params['formId']; // (+) converts string 'id' to a number
    });
  }
  LazyLoading( event: LazyLoadEvent ) {
    this.loading = true;
    if ( !event.sortField ) {
      event.sortField = 'updatedDate';
    }
    this.pageSize = event.rows;
    const pNo = (event.first + event.rows ) / event.rows;
    this.callService( pNo, this.pageSize, event.sortField, event.sortOrder === -1 ? false : true);
  }
  callService( pId: number, pSize: number, sOrder: string, sDirect: boolean ) {

    const paginginfo = new PagingAndFilteringInfo();
    paginginfo.index = pId;
    paginginfo.size = pSize;
    paginginfo.isAsc = sDirect;
    paginginfo.orderBy = sOrder;


    const fields: any = {};
    if ( this.formId !== null  )  {
        fields['FormId'] = this.formId;
      }
    paginginfo.fields = fields;

    this.apiService.submissionPaging( paginginfo).subscribe( result => {
      this.submissions = result.items;
      this.totalRecords = result.totalItems;

      this.loading = false;

    });
  }


  onView(rowData: any) {

    this.apiService.submissionGet( rowData.id).then( result => {
      this.selectedSubmissionFields = JSON.parse( result.submissionData);
      this.showViewDlg = true;

    });
  }

  onPrint(rowData: any) {}

  onDelete(rowData: any) {}
}
