import { Component, OnInit } from '@angular/core';

import {FormDesignerService} from '../../../core/custom-form/form-designer.service';

@Component({ selector: 'app-main-form-builder-view',  templateUrl: './main.component.html'})
export class MainComponent implements OnInit {

  displayAddControl = false;
  displayEditControl = false;
  deleteDisplay = false;
  selectedCtrl;
  editCtrl;
  dragElementId = 0;
  dragTargetElementId = 0;
  constructor( public designerService: FormDesignerService) { }

  ngOnInit(): void {

  }

  onAdd($event: MouseEvent) {

    this.designerService.resetPlaceHolderList();
    this.displayAddControl = true;
  }
  onConfirmAdd($event: MouseEvent) {

    this.designerService.addSelectedControls();
    this.displayAddControl = false;
  }

  onCtrlEdit(ctrl: any) {

    this.designerService.selectedCtrl = ctrl;
    this.designerService.onStartEditor( );
    // this.designerService.showHideEditor = true;
  }

  onCtrlDelete(ctrl: any) {

    this.selectedCtrl = ctrl;
    this.deleteDisplay = true;
  }

  onConfirmDelete($event: MouseEvent) {

    this.designerService.onDeleteControlBox( this.selectedCtrl );
    this.deleteDisplay = false;

  }


  onDragStart($event: DragEvent, id: any) {

    $event.dataTransfer.setData('text/move', id );
    $event.dataTransfer.effectAllowed = 'move';
    this.dragElementId = id;
    this.dragTargetElementId = 0;
    console.log( 'drag started: ' + id );
  }

  onDragEnter($event: DragEvent, id: any) {

    this.dragTargetElementId = id;
    console.log( 'drag Enter: ' + id );
    $event.preventDefault();
  }

  onDragOver($event: DragEvent, id: any) {
    // $event.dataTransfer.dropEffect = 'move';
    console.log( 'drag Over: ' + id );
    $event.preventDefault();

  }

  onDrop($event: DragEvent, id: any) {

    const srcId = $event.dataTransfer.getData( 'text/move');
    console.log( 'drag id: ' + srcId + '     drop id : ' + id );
    if ( srcId.length > 0 ) {
      this.designerService.onControlReorder(srcId, id);
      $event.preventDefault();
    }
  }


  onControlDragStart($event: DragEvent, ctrl: any) {
      $event.dataTransfer.setData('text/copy', ctrl.type );
      $event.dataTransfer.effectAllowed = 'copy';
      console.log( 'drag started: ' + ctrl.type );
  }

  onControlsDragOver($event: DragEvent) {
    $event.dataTransfer.dropEffect = 'move';
    console.log( 'onControlsDragOver: ');
    $event.preventDefault();
  }

  onControlsDrop($event: DragEvent) {

    const newId = $event.dataTransfer.getData( 'text/copy');
    if ( newId.length > 0 ) {
      this.designerService.addSelectedControl( newId);
      $event.preventDefault();
    }
  }
}
