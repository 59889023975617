import {Component, Input, OnInit} from '@angular/core';

import {BaseControlComponent} from '../base-control.component';
import {FormGroup} from '@angular/forms';
import {DropdownBox} from '../../../../models/CustomForm';
import {SelectItem} from 'primeng/api';


@Component({
  selector: 'app-dropdown-control',
  templateUrl: './dropdown-control.component.html'

})
export class DropdownControlComponent extends BaseControlComponent implements OnInit {

  // @Input() detailsForm: FormGroup;
  controls: any;
  optionList: SelectItem[] = [];
  constructor( ) {
    super();
  }


  ngOnInit(): void {

    this.controlType = this.fieldProperty.type;
    this.controlKey = this.fieldProperty.id;
    this.controlLabel = this.fieldProperty.label;
    this.controlValue = this.fieldProperty.value;
    const ctrlObj: DropdownBox =  this.fieldProperty as DropdownBox;
    console.log( this.controlKey);
    ctrlObj.options.forEach( item => {
      this.optionList.push( { label : item.name, value : item.name });
    });

  }
  /*
  getForm( FormId: number ) {
    this.formApi.get( FormId ).then( qrCode => {
      this.model = qrCode;
      this.buildForm();
    });
  } */
  /*
  buildForm() {

    this.controls = JSON.parse( this.model.configurationFields );
    const group = {};

    this.controls.forEach( control => {
        group[control.id ] = this.createControl( control );
    });

    this.myFormGroup = new FormGroup(group);
  } */
  /*
  createControl( control: any ) {
    let validArray = [];
    const ctrl = new FormControl('');
    if ( control.required ) {
      validArray.push(Validators.required);
    }
    if ( control.type === 'textBox' ){
      validArray.push(Validators.maxLength( control.maxlength ));
    }

    if ( control.type === 'numericBox' ){
      validArray.push(Validators.max( control.max ));
      validArray.push(Validators.min( control.min ));
    }
    if ( control.type === 'emailBox' ){
      validArray.push(Validators.pattern('^([0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})$'));
    }
    ctrl.setValidators( validArray );
    return ctrl;
  }
*/
  onSubmit() {
/*
    const fieldList: Field[] = [];
    this.controls.forEach( control => {
      const f = new Field();
      f.id = control.id;
      f.type = control.type;
      f.label = control.label;
      f.value = this.myFormGroup.value[f.id];
      fieldList.push( f );
    });

    const submission = new CustomFormSubmission();
    submission.formId = this.model.id;
    submission.submissionData = JSON.stringify( fieldList );
    this.formApi.submitForm( submission ).then( res => {
      this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
    }).catch(error => {
      this.showMessage('error', this.msgService.getMessage('MSG_Auth_ERROR'));
    });
    console.log( fieldList ); */
  }
  /*
  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  } */

}
