import { Component, OnInit } from '@angular/core';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-base-view',
  templateUrl: './base-view.component.html'

})
export class BaseViewComponent implements OnInit {

  constructor(public designerService: FormDesignerService) { }

  ngOnInit(): void {
  }

}
