import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-check-box-editor',
  templateUrl: './check-box-editor.component.html'

})
export class CheckBoxEditorComponent implements OnInit,  OnDestroy {


  controlLabel = '';
  defaultValue: boolean;
  isRequired = false;
  subscription: Subscription;

  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.defaultValue = this.designerService.selectedCtrl.defaultValue;

    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'checkBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.defaultValue = this.designerService.selectedCtrl.defaultValue;
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.defaultValue = this.defaultValue;
    this.designerService.onEndEditor();

  }


}
