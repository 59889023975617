import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-text-box-editor',
  templateUrl: './text-box-editor.component.html'
})
export class TextBoxEditorComponent implements OnInit,  OnDestroy {

  controlLabel = '';
  maxLength = 32;
  isRequired = false;
  subscription: Subscription;

  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.maxLength = this.designerService.selectedCtrl.maxlength;
    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'textBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.maxLength = this.designerService.selectedCtrl.maxlength;
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onNumberBoxKeyDown($event: KeyboardEvent) {
    if ( this.maxLength < 0 )
      this.maxLength = 0;
  }

  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.maxlength = this.maxLength;
    this.designerService.onEndEditor();

  }
}
