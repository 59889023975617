import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';
import {Subscription} from 'rxjs';
import {DropdownOption} from '../../../../models/CustomForm';

@Component({
  selector: 'app-dropdown-box-editor',
  templateUrl: './dropdown-box-editor.component.html'
})
export class DropdownBoxEditorComponent implements OnInit,  OnDestroy {

  controlLabel = '';
  isRequired = false;
  options: DropdownOption[] = [];
  // optionvalue = '';
  currentEditValue = '';
  subscription: Subscription;


  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.designerService.selectedCtrl.options.forEach( it => { it.selected = false; it.editMode = false; });
    this.options = JSON.parse( JSON.stringify(this.designerService.selectedCtrl.options ) );
    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'dropdownBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.designerService.selectedCtrl.options.forEach( it => { it.selected = false; it.editMode = false; });
        this.options = JSON.parse( JSON.stringify(this.designerService.selectedCtrl.options ) );
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }


  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.options = JSON.parse( JSON.stringify(this.options ) );
    this.designerService.onEndEditor();

  }


  onDrop($event: DragEvent, id: any) {

    const srcId = $event.dataTransfer.getData( 'text/move');
    console.log( 'drag id: ' + srcId + '     drop id : ' + id );
    if ( srcId.length > 0 ) {
      this.reOrderOptions( parseInt(srcId, 10 ), parseInt(id, 10 ));
      $event.preventDefault();
    }
  }
  reOrderOptions( srcId: number, destId: number ) {

    const tmpList: any[] = [];
    const srcItem = this.options.filter( x => x.id === srcId )[0];

    this.options.forEach( item => {
      if ( item.id === destId ) {
        tmpList.push(srcItem);
      }
      if ( item.id !== srcId ) {
        tmpList.push(item);
      }
    });

    this.options = tmpList;

  }

  onDragOver($event: DragEvent, id: any) {
    console.log( 'drag Over: ' + id );
    $event.preventDefault();

  }

  onDragEnter($event: DragEvent, id: any) {
    // this.dragTargetElementId = id;
    console.log( 'drag Enter: ' + id );
    $event.preventDefault();
  }

  onDragStart($event: DragEvent, id: any) {
    $event.dataTransfer.setData('text/move', id );
    $event.dataTransfer.effectAllowed = 'move';
    console.log( 'drag started: ' + id );
  }

  onAddOption($event: MouseEvent) {

    const newOpt = new DropdownOption();
    newOpt.id = this.options.length + 1;
    newOpt.name = 'خيار جديد';
    newOpt.selected = false;
    newOpt.editMode = false;
    this.options.push( newOpt );

  }

  onselectDeselect($event: MouseEvent, option: DropdownOption) {

    const currentSel = option.selected;
    this.options.forEach( item => {
      item.selected = false;
    });

    option.selected = !currentSel;

  }

  onOptionDelete(ctrl: any) {

    const tmplist: any[] = [];
    this.options.forEach( item => {
      if ( item.id !== ctrl.id) {
        tmplist.push(item);
      }
    });
    this.options = [];
    tmplist.forEach( item => {
      this.options.push( item );
    });
  }

  onOptionlEdit(option: DropdownOption) {

    if( option.editMode === false ) {
      this.options.forEach(item => {
        if (item.id !== option.id) {
          item.editMode = false;
        }
      });
      option.editMode = !option.editMode;
      this.currentEditValue = option.name;
    } else {
      option.name = this.currentEditValue;
      option.editMode = false;
    }
  }

  onOptionEditCancel(option: DropdownOption) {
    option.editMode = false;

  }
}
