import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import { ListComponent } from './list/list.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrimeNGModule} from '../primeng.module';
import {CoreModule} from '../core/core.module';
import {UtilitesModule} from '../utilites/utilites.module';
import { MainComponent } from './designer/main/main.component';
import { BaseViewComponent } from './designer/editors/base-view/base-view.component';
import { TextBoxEditorComponent } from './designer/editors/text-box-editor/text-box-editor.component';
import { NumericBoxEditorComponent } from './designer/editors/numeric-box-editor/numeric-box-editor.component';
import { EmailBoxEditorComponent } from './designer/editors/email-box-editor/email-box-editor.component';
import { TextareaBoxEditorComponent } from './designer/editors/textarea-box-editor/textarea-box-editor.component';
import {MainFormViewComponent} from './front-end/main-view/main-form-view.component';
import {TextboxControlComponent} from './front-end/controls/textbox/textbox-control.component';
import {EmailboxControlComponent} from './front-end/controls/emailbox/emailbox-control.component';
import {NumericboxControlComponent} from './front-end/controls/numericbox/numericbox-control.component';
import {DropdownControlComponent} from './front-end/controls/dropdown/dropdown-control.component';
import {MultilineboxControlComponent} from './front-end/controls/multilinebox/multilinebox-control.component';
import {CheckBoxEditorComponent} from './designer/editors/check-box/check-box-editor.component';
import {CheckboxControlComponent} from './front-end/controls/checkbox/checkbox-control.component';
import {DropdownBoxEditorComponent} from './designer/editors/dropdownBox/dropdown-box-editor.component';
import { SubmittionsComponent } from './submittions/submittions.component';



const routes: Routes = [
  { path: '', component: ListComponent },
  { path: 'add', component: EditorComponent },
  { path: 'edit/:id', component: EditorComponent },
  { path: 'view/:formId', component: MainFormViewComponent },
  { path: 'submissions/:formId', component: SubmittionsComponent }

];



@NgModule({
  declarations: [
    MainFormViewComponent,
    EditorComponent,
    ListComponent,
    MainComponent,
    BaseViewComponent,
    TextBoxEditorComponent,
    NumericBoxEditorComponent,
    EmailBoxEditorComponent,
    TextareaBoxEditorComponent,
    CheckBoxEditorComponent,
    DropdownBoxEditorComponent,
    /*BaseControlComponent,*/
    TextboxControlComponent,
    EmailboxControlComponent,
    NumericboxControlComponent,
    DropdownControlComponent,
    MultilineboxControlComponent,
    CheckboxControlComponent,
    SubmittionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNGModule,
    CoreModule,
    UtilitesModule,
    RouterModule.forChild(routes)
  ]
})
export class CustomFormModule { }
