import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-textarea-box-editor',
  templateUrl: './textarea-box-editor.component.html'
})
export class TextareaBoxEditorComponent implements OnInit,  OnDestroy {

  controlLabel = '';
  maxLength = 256;
  rows = 2;
  isRequired = false;
  subscription: Subscription;

  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.maxLength = this.designerService.selectedCtrl.maxlength;
    this.rows = this.designerService.selectedCtrl.rows;
    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'textAreaBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.maxLength = this.designerService.selectedCtrl.maxlength;
        this.rows = this.designerService.selectedCtrl.rows;
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onNumberBoxKeyDown($event: KeyboardEvent, fieldName: string ) {
    if ( fieldName === 'maxLength') {
      if (this.maxLength < 0)
        this.maxLength = 0;
    }
    if ( fieldName === 'rows') {
      if (this.rows < 2)
        this.maxLength = 2;
    }
  }

  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.maxlength = this.maxLength;
    this.designerService.selectedCtrl.rows = this.rows;
    this.designerService.onEndEditor();

  }

}
