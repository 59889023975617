import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {CustomFormService} from '../../core/custom-form.service';
import {CustomForm} from '../../models/CustomForm';
import {FormDesignerService} from '../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [MessageService]
})
export class EditorComponent implements OnInit {


  model: CustomForm = new CustomForm();
  pageTitle = 'إضافة';
  constructor( private router: Router,
               private route: ActivatedRoute,
               private apiService: CustomFormService,
               private designService: FormDesignerService,
               private messageService: MessageService, private msgService: SystemMessagingService) {
  }

  ngOnInit(): void {

    this.model.formName = '';
    this.model.configurationFields = '';
    this.designService.formControls = [];

    this.route.params.subscribe(params => {

      this.model.id = +params['id']; // (+) converts string 'id' to a number
      if ( this.model.id > 0  ) {
        this.pageTitle = ' تعديل ';
        this.doRealInit(this.model.id);
      } else {
        this.model.id = 0;
      }
    });
  }

  doRealInit( Id: number ) {

    this.apiService.get( Id ).then( result => {
      this.model = result;
      if ( this.model.configurationFields ) {
        this.designService.formControls = JSON.parse(this.model.configurationFields);
        this.designService.formControls.forEach( item => {
          item.selected = false;
        });

      } else {
        this.designService.formControls = [];
      }

    });
  }

  async onSubmit() {

    // const res = await this.altService.add(this.model);

    this.model.configurationFields = JSON.stringify( this.designService.formControls);
    if (this.model.id === 0) {
      this.apiService.add(this.model).then(res => {
        this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
        if (res.isOK) {
          setTimeout(() => {this.router.navigate(['/admin/custom-form']); }, 1000);
        }
      }).catch(error => {
        this.showMessage('error', this.msgService.getMessage('MSG_Auth_ERROR'));
      });
    } else {
      this.apiService.update(this.model).then(res => {
        this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
        if (res.isOK) {
          setTimeout(() => {this.router.navigate(['/admin/custom-form']); }, 1000);
        }
      }).catch(error => {
        this.showMessage('error', this.msgService.getMessage('MSG_Auth_ERROR'));
      });
    }
  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }

}
