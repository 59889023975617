import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-numeric-box-editor',
  templateUrl: './numeric-box-editor.component.html'

})
export class NumericBoxEditorComponent implements OnInit,  OnDestroy {


  controlLabel = '';
  min = 0;
  max = 100000;
  isRequired = false;
  subscription: Subscription;

  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.min = this.designerService.selectedCtrl.min;
    this.max = this.designerService.selectedCtrl.max;
    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'numericBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.min = this.designerService.selectedCtrl.min;
        this.max = this.designerService.selectedCtrl.max;
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onNumberBoxKeyDown($event: KeyboardEvent, fieldName: string ) {
   /* if ( fieldName === 'minBox') {
      if (this.min < this.designerService.selectedCtrl.min )
        this.min = 0;
    }
    if ( fieldName === 'maxBox') {
      if (this.max > this.designerService.selectedCtrl.max )
        this.maxLength = 2;
    }*/
  }

  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.min = this.min;
    this.designerService.selectedCtrl.max = this.max;
    this.designerService.onEndEditor();

  }


}
