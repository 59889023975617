import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeneralTransfer, PaginationModel, PagingAndFilteringInfo} from '../models/GeneralTransfer';
import {BASE_URL} from '../models/constant';
import {CustomForm, CustomFormSubmission} from '../models/CustomForm';


@Injectable({
  providedIn: 'root'
})
export class CustomFormService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient ) {}

  async getList( ) {
    const resp = await this.http
        .get<CustomForm[]>(
            `${BASE_URL}api/CustomForm/list`, this.httpOptions).toPromise();
    return resp;
  }

  async get( ItemId: number) {
    const resp = await this.http
        .get<CustomForm>(
            `${BASE_URL}api/CustomForm/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }

  async delete( ItemId: number) {
    const resp = await this.http
        .delete<GeneralTransfer>(
            `${BASE_URL}api/CustomForm/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }


  async add( ItemObject: CustomForm) {
    const resp = await this.http
        .post<GeneralTransfer>(
            `${BASE_URL}api/CustomForm/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }


  async update( ItemObject: CustomForm) {
    const resp = await this.http
        .put<GeneralTransfer>(
            `${BASE_URL}api/CustomForm/`, ItemObject, this.httpOptions).toPromise();
    return resp;
  }

  paging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/CustomForm/paging`, paginginfo, this.httpOptions);
  }


  async submissionGet( ItemId: number) {
    const resp = await this.http
        .get<CustomFormSubmission>(
            `${BASE_URL}api/CustomForm/submission/${ItemId}`, this.httpOptions).toPromise();
    return resp;
  }
  submissionPaging( paginginfo: PagingAndFilteringInfo) {

    return this.http.post<PaginationModel>( `${BASE_URL}api/CustomForm/submission-paging`, paginginfo, this.httpOptions);
  }


}
