import {OnInit, Input, Injectable} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {Field} from '../../../models/CustomForm';

@Injectable()
export abstract class BaseControlComponent implements OnInit {
    @Input() fieldProperty: Field;
    @Input() detailsForm: FormGroup;

    public controlType: string;
    public controlKey: string;
    public controlLabel: string;
    public controlValue: any;

    ngOnInit() {
        this.controlType = this.fieldProperty.type;
        this.controlKey = this.fieldProperty.id;
        this.controlLabel = this.fieldProperty.label;
        this.controlValue = this.fieldProperty.value;
    }
}
