import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormDesignerService} from '../../../../core/custom-form/form-designer.service';

@Component({
  selector: 'app-email-box-editor',
  templateUrl: './email-box-editor.component.html'
})
export class EmailBoxEditorComponent implements OnInit, OnDestroy {

  controlLabel = '';
  validation = '';
  isRequired = false;
  subscription: Subscription;

  constructor(public designerService: FormDesignerService) {
  }

  ngOnInit(): void {

    this.controlLabel = this.designerService.selectedCtrl.label;
    this.isRequired = this.designerService.selectedCtrl.required;
    this.validation = this.designerService.selectedCtrl.validation;
    this.subscription = this.designerService.editorObservable$.subscribe(data => {

      if ( this.designerService.selectedCtrl.type === 'emailBox' ) {
        this.controlLabel = this.designerService.selectedCtrl.label;
        this.isRequired = this.designerService.selectedCtrl.required;
        this.validation = this.designerService.selectedCtrl.validation;
      }
    });

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  onConfirmEdit($event: MouseEvent) {

    this.designerService.selectedCtrl.label = this.controlLabel;
    this.designerService.selectedCtrl.required = this.isRequired;
    this.designerService.selectedCtrl.validation = this.validation;
    this.designerService.onEndEditor();

  }

}
